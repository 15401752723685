/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"; 
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import { addThousandPoint, isObjEmpty, ShowDoongjiTitle2 } from "../common/Common";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

import budongImage from "../../images/flag_budong.png";
import heartOffIcon from "../../images/icon_heart_w.png";
import heartOnIcon from "../../images/icon_heart_y.png";

import noSlideImage from "../../images/no_img.png";

const DoongjiProduct = ({ inum, product, varDoongjiList, setDoongjiList }) => {
  const memberLoginState = Number(localStorage.getItem("memberLoginState"));
  const mem_idx = localStorage.getItem("mem_idx");
  const rp_idx = product.rp_idx;
  const navigator = useNavigate();

  // 사진 목록 저장
  const [varPhotoList, setPhotoList] = useState([]);
  // 매물 정보
  const [varProductData, setProductData] = useState({});

  // 이미지 로딩 상태 관리
  const [varPhotoImages, setPhotoImages] = useState([]);

  // loadImageWithRetry 함수 정의
  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // 캐시 방지용 타임스탬프 추가
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            // console.warn(`Retrying to load image: ${src} (${retries} retries left)`);
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

  // 둥지매물 사진 목록 가져오기
  const getProductPhotosList = async () => {
    const sendData = { rp_idx: rp_idx };
    await axios.post(`${CONFIG.SERVER_HOST}/_get_doongji_photo_list`, sendData).then((res) => {
      const datas = res.data;
      if (datas.retvalue === 1 && datas.results?.length > 0) {
        setPhotoList(datas.results);
      } else {
        setPhotoList([]);
      }
    });
  };

  // 페이지 정보 표시 - 최초
  useEffect(() => {
    setProductData(product);
    getProductPhotosList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  // 이미지 로딩 처리
  useEffect(() => {
    if (varPhotoList?.length > 0) {
      const initialPhotoImages = varPhotoList.map((photo) => ({
        file_name: photo.file_name,
        src: null,
        loading: true,
      }));
      setPhotoImages(initialPhotoImages);

      varPhotoList.forEach((photo, index) => {
        const sfile = photo.file_name;
        const simage = `${CONFIG.SERVER_HOST}/doongji/${sfile}?t=${new Date().getTime()}`;

        loadImageWithRetry(simage)
          .then((loadedImage) => {
            // console.log('Image loaded successfully:', loadedImage);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                src: loadedImage,
                loading: false,
              };
              return newImages;
            });
          })
          .catch((error) => {
            console.error(error.message);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                loading: false,
              };
              return newImages;
            });
          });
      });
    } else {
      setPhotoImages([]);
    }
  }, [varPhotoList]);

  // 찜 목록 등록/해제
  const onChangeJjimListHandler = async (e, rp_idx) => {
    if (memberLoginState === 0) {
      navigator('/Login');
      return false;
    }
    let jj_idx = varProductData.jj_idx;
    let jj_status = varProductData.jj_status;
    if (jj_idx === null) {
      jj_idx = 0;
      jj_status = 0;
    }
    if (jj_status === 1) jj_status = 0;
    else jj_status = 1;

    let copyList = [...varDoongjiList];
    const findIndex = copyList.findIndex(element => element.rp_idx === rp_idx);

    const sendData = { pageMode: 1, opp_idx: rp_idx, jj_idx: jj_idx, jj_mem_idx: mem_idx, jj_status: jj_status };
    await axios.post(`${CONFIG.SERVER_HOST}/_update_jjim_list`, sendData).then((res) => {
      const datas = res.data;
      if (datas.retvalue === 1) {
        copyList[findIndex].jj_idx = datas.jj_idx;
        copyList[findIndex].jj_status = jj_status;
        copyList[findIndex].mem_idx = mem_idx;
        setDoongjiList(copyList);
        setProductData({ ...varProductData, jj_idx: jj_idx, jj_status: jj_status, mem_idx: mem_idx });
      }
    });
  };

  const onGoingDetailHandler = (e, rp_idx) => {
    localStorage.setItem('scrollIndex', inum);
    localStorage.setItem('scrollTop', window.scrollY);
    navigator(`/Doongji/DoongjiView/${rp_idx}`);
  };

  return isObjEmpty(varProductData) ? null : (
    <>
      {/* 매물사진 슬라이드 */}
      <div className="slider-container">
        {varPhotoImages?.length <= 1 ?
          <div className="c_wrap" key={'photo_00'}>
            <div
              className="img_wrap2"
              style={{ textAlign: 'center', position: "relative", margin: 'auto' }}
              onClick={e => onGoingDetailHandler(e, varProductData.rp_idx)}
            >
              {varPhotoImages?.length === 0 ?
                <img src={noSlideImage} alt="" />
                :
                <div>
                  {varPhotoImages[0]?.loading ? (
                    // Display loading indicator
                    <div className="loading-indicator">
                      {/* Loading spinner */}
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <>
                      <img
                        src={varPhotoImages[0]?.src}
                        alt=""
                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', objectFit: 'cover' }}
                      />
                    </>
                  )}
                </div>
              }
            </div>
          </div>
          :
          <Swiper
            slidesPerView={1.5}
            spaceBetween={5}
            loop={true}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {varPhotoImages.map((photoObj, i) => (
              <SwiperSlide className="swiper-slide" key={'photo_' + i}>
                <div className="c_wrap">
                  <div className="img_wrap" onClick={e => onGoingDetailHandler(e, varProductData.rp_idx)}>
                    {photoObj.loading ? (
                      // Display loading indicator
                      <div className="loading-indicator">
                        {/* Loading spinner */}
                        <div className="loader"></div>
                      </div>
                    ) : (
                      <>
                        <img
                          src={photoObj.src}
                          alt=""
                          style={{ objectFit: 'cover' }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        }
      </div>

      {/* 매물정보 */}
      <div className="c_wrap" style={{ borderRadius: "0px" }} >
        <div className="info01" style={{ marginTop: "10px" }}>
          <div onClick={e => onGoingDetailHandler(e, varProductData.rp_idx)}>
            <div className="c_desc flex-row" style={{ marginBottom: "10px" }}>
              {varProductData.rp_rush_sale === 0 ? null : <span className="urgent" style={{ marginRight: "10px" }}>급매</span>}
              <div>
                <h3 style={{ fontWeight: "700" }}>{varProductData.rp_title}</h3>
              </div>
            </div>
            <div className="c_dong"><span>{varProductData.rp_dongname}</span></div>&nbsp;
            {varProductData.rp_serious === 0 ? null : <div className="c_type"><span>{varProductData.serious}</span></div>}
            <div className="c_price">
              <span className="c_rent_type">{varProductData.types}</span>&nbsp;
              <span className="price">{ShowDoongjiTitle2(varProductData, '/')}</span>
            </div>
          </div>
          <div className="wish_chk" onClick={e => onChangeJjimListHandler(e, varProductData.rp_idx)}>
            <img src={varProductData.jj_idx === null || varProductData.jj_status === 0 ? heartOffIcon : heartOnIcon} alt="" />
          </div>
        </div>
        <div onClick={e => onGoingDetailHandler(e, varProductData.rp_idx)}>
          <div className="c_hash">
            <ul>
              {varProductData.rp_serious <= 5 ?
                <li className="c_option"><span>방{varProductData.rp_rooms}</span></li>
                : varProductData.rp_serious === 6 || varProductData.rp_serious === 7 ?
                  <li className="c_option"><span>룸{varProductData.rp_rooms}</span></li>
                  : null}
              <li className="c_option"><span>
                {varProductData.rp_serious === 9 ? addThousandPoint(varProductData.rp_sale_area) : addThousandPoint(varProductData.rp_private_area)}
                평</span></li>
              {varProductData.rp_serious === 9 ? null
                : varProductData.rp_exist_floor_type === 0 ?
                  <li className="c_option"><span>{varProductData.rp_exist_floor}층</span></li>
                  : <li className="c_option">
                    <span>
                      {varProductData.rp_exist_floor_type === 1 ? "건물전체"
                        : varProductData.rp_exist_floor_type === 2 ? "지하"
                          : varProductData.rp_exist_floor_type === 3 ? "반지하"
                            : varProductData.rp_exist_floor_type === 4 ? "옥탑"
                              : null
                      }
                    </span>
                  </li>
              }
              {varProductData.rp_serious === 9 ? null
                : varProductData.rp_serious <= 5 && varProductData.rp_structure !== 0 ? <li className="c_option"><span>{varProductData.structure}</span></li>
                  : varProductData.rp_terrace === 0 ? null : <li className="c_option"><span>테라스</span></li>
              }
              {varProductData.rp_serious > 5 || varProductData.rp_can_pets === 0 ? null
                : varProductData.rp_can_pets === 1 ? <li className="c_option"><span>반려동물</span></li>
                  : <li className="c_option"><span>반려동물협의</span></li>
              }
              {varProductData.rp_serious === 9 || varProductData.rp_elevator === 0 ? null :
                <li className="c_option"><span>엘리베이터</span></li>
              }
            </ul>
          </div>
        </div>
      </div>
      {varProductData.mem_mode === 1 ?
        <span className="budong_tag"><img src={budongImage} alt="" /></span>
        : null}
      {/* 게시중일 때 노출됨  */}
      {varProductData.pr_status === 3 ?
        <div className="blind_bg" style={{ zIndex: "3", display: "block" }}>
          <div className="txt_wrap">
            <strong>블라인드 처리</strong>
            <span>처리 관리자에게 문의해주세요</span>
          </div>
        </div>
        : null}
    </>
  );
};

export default DoongjiProduct;
