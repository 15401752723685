/* ChattingArea.jsx */
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import CONFIG from '../../configs/Configs';
import { ChangeChattingDate,ChangeDateTimeFormat,GetPhotoImage } from "../../components/common/Common";

const ChattingArea = ({
  socket,
  varDisplayData,
  newMessageData,
  varChattingHistory,
  setBlockStatus,
  setReceviedData,
  onShowBigPhotoHandler,
  setMyBlockStatus,
  setBlockStatusString
}) => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const navigate = useNavigate();

  // URL을 감지하여 클릭 가능한 링크로 변환하는 함수
  const convertLinksToAnchor = (text) => {
    // text가 없을 경우 방어로직
    if (!text) return null;

    // 정규식으로 http/https 링크를 찾아 split
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    // 텍스트를 링크 단위로 나눔
    const parts = text.split(urlRegex);
    // 실제 링크로 매칭된 구간(배열)
    const matchedUrls = text.match(urlRegex);

    return parts.map((part, i) => {
      // part가 링크로 매칭되었다면, <a> 태그로 감싸서 반환
      if (matchedUrls && matchedUrls.includes(part)) {
        return (
          <a
            key={i}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#0066cc', textDecoration: 'underline' }}
            onClick={(e) => e.stopPropagation()} 
            // 혹시 상위 div 클릭 이벤트가 있다면(e.g. 사진 확대 등), 전파 막을 수 있음
          >
            {part}
          </a>
        );
      }
      // 링크가 아닌 단순 텍스트라면 그대로 반환
      return part;
    });
  };

  // 소켓 이벤트
  useEffect(() => {
    socket.on('updateMessage', (data) => {
      console.log('updateMessage', data);

      if (data.message !== null && data.message !== "") {
        // 차단/해제, 방나가기 알림 처리
        if (data.message === "상대방이 대화를 차단하였습니다.") {
          setMyBlockStatus(true);
          setBlockStatus(true);
          setBlockStatusString('※ 상대방으로부터 대화가 차단된 상태입니다.');
        } else if (data.message === "상대방이 대화차단을 해제하였습니다.") {
          setMyBlockStatus(false);
          setBlockStatus(false);
          setBlockStatusString('※ 채팅시 개인정보 유출에 유의하세요');
        } else if (data.message === "상대방이 채팅방에서 나갔습니다.") {
          navigate(-1);
          alert(data.message);
        }

        // 일반 메시지
        let newElement = { ...newMessageData };
        newElement.cl_mem_idx = data.mem_idx;
        newElement.cl_type = 0;
        newElement.cl_comment = data.message;
        newElement.cl_rdate = ChangeDateTimeFormat();
        setReceviedData(newElement);
      }
    });

    socket.on('updateImage', (data) => {
      console.log('updateImage', data);
      if (data.filename) {
        let newElement = { ...newMessageData };
        newElement.cl_mem_idx = data.mem_idx;
        newElement.cl_type = 1;
        newElement.cl_file = data.filename;
        newElement.cl_preview = `${CONFIG.SERVER_HOST}/` + GetPhotoImage(data.filename, 'chatting');
        newElement.cl_rdate = ChangeDateTimeFormat();
        setReceviedData(newElement);
      }
    });

    // cleanup
    return () => {
      socket.off('updateMessage');
      socket.off('updateImage');
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {varChattingHistory.map((chats, i) => {
        // 내 메시지인지, 상대방 메시지인지 구분
        const isMyMessage = (chats.cl_mem_idx === mem_idx);

        if (chats.cl_type === 0) {
          // === 문자형 메시지 ===
          return (
            <div
              key={`chats_${i}`}
              className={isMyMessage ? "msg_box send_msg" : "msg_box receive_msg"}
            >
              {!isMyMessage && (
                <div className="name">
                  {chats.cl_mem_idx === varDisplayData.ch_mem_idx
                    ? varDisplayData.memName
                    : varDisplayData.ownName}
                </div>
              )}
              <div className="msg msg_text">
                {/* ✨ URL을 자동 링크로 변환 */}
                <span>{convertLinksToAnchor(chats.cl_comment)}</span>
              </div>
              <div className="time">{ChangeChattingDate(chats.cl_rdate)}</div>
            </div>
          );
        } else {
          // === 이미지 메시지 ===
          return (
            <div
              key={`chats_${i}`}
              className={isMyMessage ? "msg_box send_msg" : "msg_box receive_msg"}
            >
              {!isMyMessage && (
                <div className="name">
                  {chats.cl_mem_idx === varDisplayData.ch_mem_idx
                    ? varDisplayData.memName
                    : varDisplayData.ownName}
                </div>
              )}
              <div
                className="msg img_wrap"
                onClick={(e) => onShowBigPhotoHandler(e, chats.cl_preview)}
              >
                <img src={chats.cl_preview} alt="" className="chat_img" />
              </div>
              {/* 이미지 메시지에도 cl_comment가 있다면 마찬가지로 링크 처리 */}
              {chats.cl_comment && (
                <div className="msg msg_text">
                  <span>{convertLinksToAnchor(chats.cl_comment)}</span>
                </div>
              )}
              <div className="time">{ChangeChattingDate(chats.cl_rdate)}</div>
            </div>
          );
        }
      })}
    </>
  );
};

export default ChattingArea;
