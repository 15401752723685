/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{ useState,useEffect } from "react"; 
import {AddOptionToString,ExceptOptionToString,isObjEmpty, DisplayNumberWithZero} from "../common/Common";

const DetailLocation = ({varProductData,setProductData,varSubwayLines,varSubwayStations,onChangeInputHandler}) => {

  const [varSelectedStations,setSelectedStations] = useState([]);
  //const [varSelectedLine,setSelectedLine] = useState('');
  const [isLanguageVisible, setIsLanguageVisible] = useState(false); // 언어 선택 섹션의 표시 여부 관리


  //페이지 정보 표시 - 최초
  useEffect(() => {
    //console.log(varProductData,isObjEmpty(varProductData));
    if(varProductData !== undefined && !isObjEmpty(varProductData)) {
      //console.log('역',varProductData.rp_subway_line);
      if(varProductData.rp_subway_line !== "") {
        const newStationList = varSubwayStations.filter(element=>element.subway_line===varProductData.rp_subway_line);
        //console.log(newStationList);
        setSelectedStations(newStationList);
        //setSelectedLine(varProductData.rp_subway_line);
      }

      if (varProductData.rp_translate === 1) {
        setIsLanguageVisible(true);
      } else {
        setIsLanguageVisible(false);
      }
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varProductData]);

  //지하철노선을 선택하면 해당 역들의 목록을 설정
  const onSelectSubwayLineHandler = (e) => {
    const { value } = e.currentTarget;
    //setSelectedLine(value);
    if(value === "") {
      setSelectedStations([]);
    } else {
      const newStationList = varSubwayStations.filter(element=>element.subway_line===value);
      setSelectedStations(newStationList);
    }
    setProductData({...varProductData,rp_subway_line:value,rp_subway_station:0});
  }

  //통역언어
  const onChangeLanguageHandler = (e) => {
    const { value } = e.currentTarget;
    const bmode = e.currentTarget.checked;
    console.log(value,varProductData.rp_languages.includes(value));
    let newString = "";
    if(bmode) {
      if(varProductData.rp_languages==="" || !varProductData.rp_languages.includes(value)) {
        newString = AddOptionToString(value,varProductData.rp_languages,',');
        console.log(newString);
        setProductData({...varProductData,rp_languages:newString});
      }
    } else {
      if(varProductData.rp_languages!=="" || varProductData.rp_languages.includes(value)) {
        newString = ExceptOptionToString(value,varProductData.rp_languages,',');
        setProductData({...varProductData,rp_languages:newString});
      }
    }
  }
  const onChangeRadioInputHandler = (e) => {
    const { name,value } = e.currentTarget;
    if(name === "rp_translate") {
      const isVisible = Number(value) === 1;
      setIsLanguageVisible(isVisible); // 상태 업데이트
      if (!isVisible) {
        // 통역 불가로 설정 시 언어 선택 초기화
        setProductData({ ...varProductData, rp_translate: 0, rp_languages: "" });
      } else {
        setProductData({ ...varProductData, rp_translate: 1 });
      }
    } else {
      setProductData({...varProductData,[name]:value});
    }
  }

  return isObjEmpty(varProductData)?null:(
    <>
      <div className="tit07_1">상세 정보</div>
      {/* 반려동물-주택류만 가능 */}
      {varProductData.rp_serious > 5?null:
        <div className="form_wrap2">
          <div className="tit07_2">반려동물<span className="star_red"> (필수)</span></div>
          <div className="one-line line-left-row">
            <li className="terms_chk35" style={{marginRight:"5px"}}>
              <input type="radio" name="rp_can_pets" className="chk1" id="rp_can_pets1" value="1"
                    defaultChecked={varProductData.rp_can_pets===1} onChange={e=>onChangeInputHandler(e)}/>
              <i></i><label htmlFor="rp_can_pets1">가능</label>
            </li>
            <li className="terms_chk35" style={{marginRight:"5px"}}>
              <input type="radio" name="rp_can_pets" className="chk1" id="rp_can_pets0" value="0"
                    defaultChecked={varProductData.rp_can_pets===0} onChange={e=>onChangeInputHandler(e)}/>
              <i></i><label htmlFor="rp_can_pets0">불가능</label>
            </li>
            <li className="terms_chk35">
              <input type="radio" name="rp_can_pets" className="chk1" id="rp_can_pets2" value="2"
                    defaultChecked={varProductData.rp_can_pets===2} onChange={e=>onChangeInputHandler(e)}/>
              <i></i><label htmlFor="rp_can_pets2">협의</label>
            </li>
          </div>
        </div>
      }
      <div className="form_wrap2" style={{marginBottom:"5px"}}>
        <div className="tit07_2">지하철역</div>
        <div className="one-line line-row">
          <select name="rp_subway_line" className="two-subway" value={varProductData.rp_subway_line||""} onChange={e=>onSelectSubwayLineHandler(e)}>
            <option value="">노선선택</option>
            {varSubwayLines.map((lines,i)=>(
              <option key={'lines_'+i} value={lines.subway_line}>{lines.subway_line}</option>
            ))}
          </select>
          <select name="rp_subway_station" className="two-subway" value={varProductData.rp_subway_station||""} onChange={e=>onChangeInputHandler(e)}>
            <option value={0}>역선택</option>
            {varSelectedStations.map((station,i)=>(
              <option key={'station_'+i} value={station.subway_idx}>{station.subway_name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="form_wrap3">
        <div className="one-line">
          <span className="danwe_txt2" style={{marginTop:"10px",marginRight:"10px"}}>도보</span>
          <input type="text" name="rp_subway_more" style={{width:"120px",textAlign:"right"}} placeholder="0" value={DisplayNumberWithZero(varProductData.rp_subway_more)} onChange={e=>onChangeInputHandler(e)}/>
          <span className="danwe_txt" style={{marginTop:"10px",marginLeft:"10px"}}>분</span>
        </div>
      </div>
      <div className="form_wrap2" style={{marginBottom:"5px"}}>
        <div className="tit07_2">버스정류소</div>
        <div className="one-line">
          <input type="text" name="rp_bus" value={varProductData.rp_bus||""} placeholder="버스정류소를 입력하세요" onChange={e=>onChangeInputHandler(e)}/>
        </div>
      </div>
      <div className="form_wrap3">
        <div className="one-line">
          <span className="danwe_txt2" style={{marginTop:"10px",marginRight:"10px"}}>도보</span>
          <input type="text" name="rp_bus_more" style={{width:"120px",textAlign:"right"}} placeholder="0" value={DisplayNumberWithZero(varProductData.rp_bus_more)} onChange={e=>onChangeInputHandler(e)}/>
          <span className="danwe_txt" style={{marginTop:"10px",marginLeft:"10px"}}>분</span>
        </div>
      </div>
      <div className="form_wrap2">
        <div className="tit07_2">대형마트</div>
        <div className="one-line" style={{textAlign:"right"}}>
          <span className="danwe_txt2" style={{marginTop:"10px",marginRight:"10px"}}>도보</span>
          <input type="text" name="rp_market_more" style={{width:"120px",textAlign:"right"}} placeholder="0" value={DisplayNumberWithZero(varProductData.rp_market_more)} onChange={e=>onChangeInputHandler(e)}/>
          <span className="danwe_txt" style={{marginTop:"10px",marginLeft:"10px"}}>분</span>
        </div>
      </div>
      <div className="form_wrap2">
        <div className="tit07_2">편의점</div>
        <div className="one-line" style={{textAlign:"right"}}>
          <span className="danwe_txt2" style={{marginTop:"10px",marginRight:"10px"}}>도보</span>
          <input type="text" name="rp_mart_more" style={{width:"120px",textAlign:"right"}} placeholder="0" value={DisplayNumberWithZero(varProductData.rp_mart_more)} onChange={e=>onChangeInputHandler(e)}/>
          <span className="danwe_txt" style={{marginTop:"10px",marginLeft:"10px"}}>분</span>
        </div>
      </div> 
      <div className="form_wrap2" style={{marginBottom:"5px"}}>
        <div className="tit07_2">인근 학교</div>
        <div className="one-line">
          <input type="text" name="rp_school" value={varProductData.rp_school||""} placeholder="학교명을 입력하세요" onChange={e=>onChangeInputHandler(e)}/>
        </div>
      </div>
      <div className="form_wrap3">
        <div className="one-line">
          <span className="danwe_txt2" style={{marginTop:"10px",marginRight:"10px"}}>도보</span>
          <input type="text" name="rp_school_more" style={{width:"120px",textAlign:"right"}} placeholder="0" value={DisplayNumberWithZero(varProductData.rp_school_more)} onChange={e=>onChangeInputHandler(e)}/>
          <span className="danwe_txt" style={{marginTop:"10px",marginLeft:"10px"}}>분</span>
        </div>
      </div>
      <div className="form_wrap2">
        <div className="tit07">CCTV</div>
        <div className="one-line line-left-row">
          <li className="terms_chk35" style={{marginRight:"20px"}}>
            <input type="radio" name="rp_cctv" className="chk1" id="rp_cctv1" value="1"
                  defaultChecked={varProductData.rp_cctv===1} onChange={e=>onChangeInputHandler(e)}/>
            <i></i><label htmlFor="rp_cctv1">있음</label>
          </li>
          <li className="terms_chk35">
            <input type="radio" name="rp_cctv" className="chk1" id="rp_cctv0" value="0"
                  defaultChecked={varProductData.rp_cctv===0} onChange={e=>onChangeInputHandler(e)}/>
            <i></i><label htmlFor="rp_cctv0">없음</label>
          </li>
        </div>
      </div>  
      <div className="form_wrap2">
        <div className="tit07">통역</div>
        <div className="one-line line-left-row">     
          <li className="terms_chk35" style={{marginRight:"20px"}}>
            <input type="radio" name="rp_translate" className="chk1" id="rp_translate1" value="1"
                  checked={varProductData.rp_translate===1} onChange={e=>onChangeRadioInputHandler(e)}/>
            <i></i><label htmlFor="rp_translate1">가능</label>
          </li>
          <li className="terms_chk35">
            <input type="radio" name="rp_translate" className="chk1" id="rp_translate0" value="0"
                  checked={varProductData.rp_translate===0} onChange={e=>onChangeRadioInputHandler(e)}/>
            <i></i><label htmlFor="rp_translate0">불가</label>
          </li>
        </div>
      </div>
      {/* 조건부 렌더링을 사용하여 통역 가능 시 언어 선택 섹션 표시 */}
      {isLanguageVisible && (
        <div className="form_wrap3" style={{ marginBottom: "0px" }}>
          <div className="one-line line-left-row" style={{ marginBottom: "0px" }}>
            <li className="terms_chks" style={{ marginRight: "20px" }}>
              <input type="checkbox" name="rp_languages1" className="chk1" id="rp_languages1" value="영어"
                    checked={varProductData.rp_languages.includes('영어')} onChange={e => onChangeLanguageHandler(e)} />
              <i></i><label htmlFor="rp_languages1">영어</label>
            </li>
            <li className="terms_chks" style={{ marginRight: "20px" }}>
              <input type="checkbox" name="rp_languages2" className="chk1" id="rp_languages2" value="중국어"
                    checked={varProductData.rp_languages.includes('중국어')} onChange={e => onChangeLanguageHandler(e)} />
              <i></i><label htmlFor="rp_languages2">중국어</label>
            </li>
            <li className="terms_chks">
              <input type="checkbox" name="rp_languages3" className="chk1" id="rp_languages3" value="일본어"
                    checked={varProductData.rp_languages.includes('일본어')} onChange={e => onChangeLanguageHandler(e)} />
              <i></i><label htmlFor="rp_languages3">일본어</label>
            </li>
          </div>
        </div>
      )}
      <div className="gray_txt_st01" style={{ textAlign: "right" }}>외국어로 매물을 소개하실 수 있는 경우 체크해주세요.</div>
    </>
  );
}
export default DetailLocation;