import React from "react"; 

import { ChangeToSquareMeter,ChangeDateStringToKorean,isNull } from "../common/Common";

import reloadIcon from "../../images/reload2.png";

//매물 가격 정보 표시
const DoongjiProductInfo = ({varProductData}) => {
  //면적 단위변경
  const onExchangeUniHandler = (e,imode) => {
    e.preventDefault();
    const areaBox1 = document.querySelector('.viewArea1');
    console.log(areaBox1);
    const areaBox2 = document.querySelector('.viewArea2');
    if(areaBox1.classList.contains('txt01')) {
      areaBox1.classList.remove('txt01');
      areaBox1.classList.add('txt02');
      areaBox2.classList.remove('txt02');
      areaBox2.classList.add('txt01');
    } else {
      areaBox1.classList.remove('txt02');
      areaBox1.classList.add('txt01');
      areaBox2.classList.remove('txt01');
      areaBox2.classList.add('txt02');
    }
  }

  return (
    <div className="info02">
      <div className="tit07_1">매물 정보</div>
      <div className="table_st03">
        <table>
          <colgroup><col width="35%"/><col width="65%"/></colgroup>
          <thead></thead>
          <tbody>
            <tr>
              <th>매물명</th>
              <td><span>{varProductData.rp_title}</span></td>
            </tr>
            <tr>
              <th>주소</th>
              {varProductData.rp_hidden_address===1?<td><span>주소 비공개</span></td>:
                <td>
                  <span>{varProductData.rp_address1}&nbsp;
                      {varProductData.rp_serious <= 4?varProductData.rp_address2
                      :varProductData.rp_serious === 8 || varProductData.rp_serious === 9?null
                      :varProductData.rp_address_hidden===1?null:varProductData.rp_address2
                      } 
                  </span>
                  <br/>
                  <span className="doro">
                    <span className="bt">도로명</span>{varProductData.rp_roadname}
                  </span> 
                </td>
              }
            </tr>
            {((varProductData.rp_serious <= 3 && varProductData.rp_hidden_address===0) || (varProductData.rp_serious === 8 && varProductData.rp_address_hidden===0 && varProductData.rp_hidden_address===0))?
              <tr>
                <th>{varProductData.rp_serious<=2?"단지명"
                    :varProductData.rp_serious===3?"빌라명":"건물명"}
                </th>
                <td>
                  <span>{varProductData.rp_name}</span>
                </td>
              </tr>
              :null
            }
            {varProductData.rp_serious > 4 || varProductData.rp_address_hidden===1 || varProductData.rp_hidden_address===1?null:
              <tr>
                <th>동.호수</th>
                <td>
                  <span>{isNull(varProductData.rp_bd_num)?null:varProductData.rp_bd_num+'동'}&nbsp;{isNull(varProductData.rp_rm_num)?null:varProductData.rp_rm_num+'호'}</span>
                </td>
              </tr>
            }
            <tr>
              <th>매물종류</th>
              <td><span>{varProductData.serious}</span></td>
            </tr>
            {varProductData.rp_serious===9?null:   /* 토지만 제외 */
              <tr>
                <th>건축물 용도</th>
                <td><span>{varProductData.purpose}</span></td>
              </tr>
            }
            {varProductData.rp_serious!==6?null:  /* 상가 */
              <tr>
                <th>업종/종목</th>
                <td><span>{varProductData.rp_sector||"없음"}/{varProductData.rp_items||"없음"}</span></td>
              </tr>
            }
            {varProductData.rp_serious!==7?null:  /* 사무실 */
              <tr>
                <th>테마</th>
                <td><span>{varProductData.rp_theme||"없음"}</span></td>
              </tr>
            }
            {varProductData.rp_serious!==8 && varProductData.rp_serious!==9?null:  /* 빌딩과 토지 */
              <tr>
                <th>{varProductData.rp_serious===8?"용도지역":"용도지역/지목"}</th>
                <td><span>{varProductData.purposeregion}{varProductData.rp_serious===9?"/"+varProductData.jimok:null}</span></td>
              </tr>
            }
            {varProductData.rp_serious!==10?null:  /* 공장 */
              <tr>
                <th>건축구조</th>
                <td><span>{varProductData.bd_structure}</span></td>
              </tr>
            }
            <tr>
              <th>{varProductData.rp_serious===9?"대지면적"
                  :varProductData.rp_serious===4||varProductData.rp_serious===5||varProductData.rp_serious===8||varProductData.rp_serious===10?"면적(대지/연면적)"
                  :"면적(공급/전용)"}
              </th>
              <td>
                <div className="extent_txt">
                  {varProductData.rp_serious===9?
                    <>
                      <span className="txt01 viewArea1" >{varProductData.rp_sale_area} 평 </span>
                      <span className="txt02 viewArea2">{ChangeToSquareMeter(varProductData.rp_sale_area,1)} ㎡</span>
                      <span className="chng_btn"><img src={reloadIcon} alt="" onClick={e=>onExchangeUniHandler(e,0)}/> </span>
                    </>
                  :
                    <>
                      <span className="txt01 viewArea1">{varProductData.rp_sale_area} 평 / {varProductData.rp_private_area} 평 </span>
                      <span className="txt02 viewArea2">{ChangeToSquareMeter(varProductData.rp_sale_area,1)} ㎡ / {ChangeToSquareMeter(varProductData.rp_private_area,1)} ㎡</span>
                      <span className="chng_btn"><img src={reloadIcon} alt="" onClick={e=>onExchangeUniHandler(e,1)}/> </span>
                    </>
                  }
                </div>
              </td>
            </tr>
            {varProductData.rp_serious!==8?null:
              <tr>
                <th>건물위치</th>
                <td><span>{varProductData.bd_location}</span></td>
              </tr>
            }
            {varProductData.rp_serious===9?null:
              (varProductData.rp_serious===8?
                <tr>
                <th>화장실 수</th>
                <td>
                  <span>{varProductData.rp_baths}
                        {varProductData.rp_serious<6?"":
                          varProductData.rp_bath_side===0?""
                          :varProductData.rp_bath_side===1?" (내부)"
                          :varProductData.rp_bath_side===2?" (외부)":" (내외부)"}
                  </span>
                </td>
              </tr>
              :
              <tr>
                <th>{varProductData.rp_serious===6 || varProductData.rp_serious===7?"룸":"방"} / 화장실 수</th>
                <td>
                  <span>{varProductData.rp_rooms}</span><span className="mid_bar"> / </span>
                  <span>{varProductData.rp_baths}
                        {varProductData.rp_serious<6?"":
                          varProductData.rp_bath_side===0?""
                          :varProductData.rp_bath_side===1?" (내부)"
                          :varProductData.rp_bath_side===2?" (외부)":" (내외부)"}
                  </span>
                </td>
              </tr>
              )
            }
            {varProductData.rp_serious>5?null:
              <tr>
                <th>내부구조</th>
                <td><span>{varProductData.structure}</span></td>
              </tr>
            }
            {varProductData.rp_serious===9?null:
              <tr>
                <th>냉/난방</th>
                <td><span>{varProductData.coolheat}</span></td>
              </tr>
            }
            {varProductData.rp_serious!==10?null:
              <>
                <tr>
                  <th>단독사용</th>
                  <td><span>{varProductData.rp_use_alone===1?"단독사용가능":"단독사용 불가능"}</span></td>
                </tr>
                <tr>
                  <th>전기전력/층고</th>
                  <td><span>{varProductData.rp_electronic+"kw / "+varProductData.rp_floor_height+"m"}</span></td>
                </tr>
              </>
            }
            {varProductData.rp_serious===9?null:  /* 토지 제외 */
              <>
                <tr>
                  <th>해당층</th>
                  <td>
                    <span>{varProductData.rp_exist_floor_type===0?varProductData.rp_exist_floor+"층"
                          :varProductData.rp_exist_floor_type===1?"건물전체"
                          :varProductData.rp_exist_floor_type===2?"지하"
                          :varProductData.rp_exist_floor_type===3?"반지하"
                          :varProductData.rp_exist_floor_type===4?"옥탑":""
                          }
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>총층수</th>
                  <td><span>{varProductData.rp_total_floor}층</span></td>
                </tr>
                <tr>
                  <th>주차</th>
                  <td>
                    <span>{varProductData.rp_can_parking===0?"불가능"
                          :"가능 ( "+varProductData.rp_exist_parking+"대 / 총 "+varProductData.rp_total_parking+"대 )"
                          }
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>방향(현관기준)</th>
                  <td><span>{varProductData.direction}</span></td>
                </tr>
                <tr>
                  <th>엘리베이터</th>
                  <td><span>{varProductData.rp_elevator===1?"있음":"없음"}</span>
                  </td>
                </tr>
              </>
            }
            {varProductData.rp_serious===9 || varProductData.rp_serious < 5 ?null:  /* 토지제외 */
              <tr>
                <th>테라스</th>
                <td><span>{varProductData.rp_terrace===1?"있음":"없음"}</span></td>
              </tr>
            }
            <tr>
              <th>입주 가능일</th>
              <td>
                <span>{varProductData.rp_can_enter===0?"즉시입주":"협의"}</span>
                {varProductData.rp_can_enter===0?null:
                  <>
                    <span className="mid_bar"> | </span>
                    <span>{ChangeDateStringToKorean(varProductData.rp_can_enter_date)} {varProductData.rp_can_enter_state===0?"이전":"이후"}</span>
                  </>
                }
              </td>
            </tr>
            {varProductData.rp_serious===9?null:  /* 토지 제외 */
              <tr>
                <th>건축물사용승인일</th>
                <td><span>{ChangeDateStringToKorean(varProductData.rp_used_app_date)}</span></td>
              </tr>
            } 
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default DoongjiProductInfo;