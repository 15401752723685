/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { ShowDoongjiTitle2, GetPhotoImage, addThousandPoint } from "../common/Common";

import heartOffIcon from "../../images/icon_heart_g.png";
import heartOnIcon from "../../images/icon_heart_brown.png";

const PremiumDoongjiList = ({ varSearchRegionList }) => {
  const mem_idx = Number(localStorage.getItem("mem_idx"));
  const memberLoginState = Number(localStorage.getItem("memberLoginState"));

  const navigator = useNavigate();

  // 검색된 프리미엄 매물목록 관련
  const [varTotalCount, setTotalCount] = useState(0);
  const [varPremiumList, setPremiumList] = useState([]);

  // 이미지 로딩 상태 관리
  const [varPremiumImages, setPremiumImages] = useState([]);

  // 이미지 로딩 및 재시도 함수
  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // 캐시 방지용 타임스탬프 추가
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };

  // 프리미엄매물 검색후 목록 가져오기
  const getDoongjiPremiumList = async () => {
    const sendData = { regionList: varSearchRegionList, mem_idx: mem_idx };
    await axios.post(`${CONFIG.SERVER_HOST}/_get_doongji_premium_list`, sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if (datas.retvalue === 1) {
        // 검색된 전체수량
        if (datas.totalcount > 0) {
          setTotalCount(datas.totalcount);
          // 검색된 목록
          setPremiumList(datas.results);
        }
      }
    });
  }

  // 페이지 정보 표시 - 최초
  useEffect(() => {
    getDoongjiPremiumList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 이미지 로딩
  useEffect(() => {
    if (varPremiumList.length > 0) {
      const initialPremiumImages = varPremiumList.map((slide) => ({
        rp_idx: slide.rp_idx,
        src: null,
        loading: true,
      }));
      setPremiumImages(initialPremiumImages);

      varPremiumList.forEach((slide, index) => {
        const sfile = slide.file_name;
        const simage =
          `${CONFIG.SERVER_HOST}/` +
          GetPhotoImage(sfile, 'doongji') +
          `?t=${new Date().getTime()}`;
        loadImageWithRetry(simage)
          .then((loadedImage) => {
            setPremiumImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                src: loadedImage,
                loading: false,
              };
              return newImages;
            });
          })
          .catch((error) => {
            console.error(error.message);
            setPremiumImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                loading: false,
              };
              return newImages;
            });
          });
      });
    } else {
      setPremiumImages([]);
    }
  }, [varPremiumList]);

  // 프리미엄매물을 찜목록으로 등록/해제
  const onChangeJjimListHandler = async (e, rp_idx) => {
    if (memberLoginState === 0) {
      navigator('/Login');
      return false;
    }
    let copyList = [...varPremiumList];
    const findIndex = copyList.findIndex(element => element.rp_idx === rp_idx);

    let jj_idx = copyList[findIndex].jj_idx;
    let jj_status = copyList[findIndex].jj_status;
    console.log(rp_idx, jj_idx, jj_status);
    if (jj_idx === null) {
      jj_idx = 0;
      jj_status = 0;
    }
    if (jj_status === 1) jj_status = 0;
    else jj_status = 1;

    const sendData = { pageMode: 1, opp_idx: rp_idx, jj_idx: jj_idx, jj_mem_idx: mem_idx, jj_status: jj_status };
    await axios.post(`${CONFIG.SERVER_HOST}/_update_jjim_list`, sendData).then((res) => {
      const datas = res.data;
      console.log(res.data);
      if (datas.retvalue === 1) {
        // 검색된 목록 업데이트
        copyList[findIndex].jj_idx = datas.jj_idx;
        copyList[findIndex].jj_status = jj_status;
        copyList[findIndex].mem_idx = mem_idx;
        setPremiumList(copyList);
      }
    });
  }

  return varTotalCount === 0 ? null : (
    <div className="main_premium_wrap">
      <div className="main_box" style={{ paddingBottom: "10px" }}>
        <div className="tit01">PREMIUM</div>
        <Swiper autoplay={{ delay: 2500, disableOnInteraction: false }} pagination={{ clickable: true }} modules={[Pagination, Autoplay]}>
          <div className="swiper-container main_box01 slide_box_st01">
            <div className="swiper-pagination"></div>
            <div className="swiper-wrapper">
              {varPremiumList.map((slide, ii) => {
                const imageObj = varPremiumImages.find((img) => img.rp_idx === slide.rp_idx);
                return (
                  <SwiperSlide className="swiper-slide" key={'slidep' + ii}>
                    <Link to={`/Doongji/DoongjiView/${slide.rp_idx}`}>
                      <div className="img_wrap" style={{ position: "relative", height: "280px", margin: "0 auto" }}>
                        {imageObj && imageObj.loading ? (
                          <div className="loading-indicator" style={{ height: "280px", position: 'relative' }}>
                            <div className="loading-spinner"></div>
                          </div>
                        ) : (
                          <img src={imageObj?.src} alt="" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', objectFit: 'cover', width: '100%', height: '100%' }} />
                        )}
                        {/* <img src={`${CONFIG.SERVER_HOST}/water_m.png`} alt="Watermark" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' , width: '85px', height: 'auto', objectFit: 'cover', pointerEvents: 'none' }} /> */}
                      </div>
                    </Link>
                    <div className="c_wrap">
                      <div className="info01" style={{ marginTop: "10px" }}>
                        <Link to={`/Doongji/DoongjiView/${slide.rp_idx}`}>
                          <div className="c_desc flex-row" style={{ marginBottom: "10px" }}>
                            {slide.rp_rush_sale === 0 ? null :
                              <span className="urgent" style={{ marginRight: "10px" }}>급매</span>
                            }
                            <div>
                              <h3 style={{ fontWeight: "700" }}>{slide.rp_title}</h3>
                            </div>
                          </div>
                          <div className="c_dong"><span>{slide.rp_dongname}</span></div>&nbsp;
                          <div className="c_type"><span>{slide.rp_mode === 1 ? "직거래" : "부동산"}</span></div>
                          <div className="c_price">
                            <span className="c_rent_type">{slide.types}</span>&nbsp;&nbsp;
                            <span className="price">{ShowDoongjiTitle2(slide, '/')}</span>
                          </div>
                        </Link>
                        <div className="prim_chk"><img src={slide.jj_idx === null || slide.jj_status === 0 ? heartOffIcon : heartOnIcon} alt="" onClick={e => onChangeJjimListHandler(e, slide.rp_idx)} /></div>
                      </div>
                      <Link to={`/Doongji/DoongjiView/${slide.rp_idx}`}>
                        <div className="c_hash">
                          <ul>
                            <li className="c_type"><span style={{ color: "#65401E" }}>{slide.serious}</span></li>
                            {slide.rp_serious <= 5 ?
                              <li className="c_option"><span>방{slide.rp_rooms}</span></li>
                              : slide.rp_serious === 6 || slide.rp_serious === 7 ?
                                <li className="c_option"><span>룸{slide.rp_rooms}</span></li>
                                : null}
                            <li className="c_option"><span>{slide.rp_serious === 9 ? addThousandPoint(slide.rp_sale_area) : addThousandPoint(slide.rp_private_area)}평</span></li>
                            {slide.rp_serious === 9 ? null
                              : slide.rp_exist_floor_type === 0 ?
                                <li className="c_option"><span>{slide.rp_exist_floor}층</span></li>
                                : <li className="c_option">
                                  <span>
                                    {slide.rp_exist_floor_type === 1 ? "건물전체"
                                      : slide.rp_exist_floor_type === 2 ? "지하"
                                        : slide.rp_exist_floor_type === 3 ? "반지하"
                                          : slide.rp_exist_floor_type === 4 ? "옥탑"
                                            : null}
                                  </span>
                                </li>
                            }
                            {slide.rp_serious === 9 ? null
                              : slide.rp_serious <= 5 && slide.rp_structure !== 0 ?
                                <li className="c_option"><span>{slide.structure}</span></li>
                                : slide.rp_terrace === 0 ? null :
                                  <li className="c_option"><span>테라스</span></li>
                            }
                            {slide.rp_serious > 5 || slide.rp_can_pets === 0 ? null
                              : slide.rp_can_pets === 1 ? <li className="c_option"><span>반려동물</span></li>
                                : <li className="c_option"><span>반려동물협의</span></li>
                            }
                          </ul>
                        </div>
                      </Link>
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
          </div>
        </Swiper>
      </div>
    </div>
  );
}
export default PremiumDoongjiList;
