import React, { useState,useEffect } from "react"; 
import { Link,useNavigate } from "react-router-dom";
import axios from 'axios';
import CONFIG from '../../configs/Configs';
import { isObjEmpty,GetPhotoImage,ShowDoongjiTitle2,addThousandPoint } from "../common/Common";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';     

import noSlideImage from "../../images/no_img.png";

const JjimProduct = ({varIndex,varMode,varProduct,varDeleteList,setDeleteList}) => {
  const navigate = useNavigate();
  let ad_idx = 0;
  if(varMode === 1){
    ad_idx = Number(varProduct.rp_idx);
  } 
  else{
    ad_idx = Number(varProduct.jt_idx);
  } 

  //사진 목록 저장
  const [varPhotoList,setPhotoList] = useState([]);
  //매물정보
  const [varProductData,setProductData] = useState({});

  // 이미지 로딩 상태 관리
  const [varPhotoImages, setPhotoImages] = useState([]);

  //사진 목록 가져오기
  const getProductPhotosList = async() => {
    const sendData = {pageMode:varMode,ad_idx:ad_idx};
    await axios.post(`${CONFIG.SERVER_HOST}/_get_jjim_photo_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 1 && datas.results?.length > 0) {
        //검색된 목록
        setPhotoList(datas.results);
      }
    });
  }

  // loadImageWithRetry 함수 정의
  const loadImageWithRetry = (src, retries = 20, delay = 2000) => {
    return new Promise((resolve, reject) => {
      const attempt = () => {
        const img = new Image();
        img.src = src + '&t=' + new Date().getTime(); // 캐시 방지용 타임스탬프 추가
        img.onload = () => resolve(img.src);
        img.onerror = () => {
          if (retries > 0) {
            // console.warn(`Retrying to load image: ${src} (${retries} retries left)`);
            retries--;
            setTimeout(attempt, delay);
          } else {
            reject(new Error(`Failed to load image: ${src}`));
          }
        };
      };
      attempt();
    });
  };
  //페이지 정보 표시 - 최초
  useEffect(() => {
    setProductData(varProduct);
    getProductPhotosList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varProduct]);

  // 이미지 로딩 처리
  useEffect(() => {
    if (varPhotoList?.length > 0) {
      const initialPhotoImages = varPhotoList.map((photo) => ({
        file_name: photo.file_name,
        src: null,
        loading: true,
      }));
      setPhotoImages(initialPhotoImages);

      varPhotoList.forEach((photo, index) => {
        const sfile = photo.file_name;
        const simage = varMode===1?`${CONFIG.SERVER_HOST}/doongji/${sfile}?t=${new Date().getTime()}`:`${CONFIG.SERVER_HOST}/jangter/${sfile}?t=${new Date().getTime()}`;
        loadImageWithRetry(simage)
          .then((loadedImage) => {
            // console.log('Image loaded successfully:', loadedImage);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                src: loadedImage,
                loading: false,
              };
              return newImages;
            });
          })
          .catch((error) => {
            console.error(error.message);
            setPhotoImages((prevImages) => {
              const newImages = [...prevImages];
              newImages[index] = {
                ...newImages[index],
                loading: false,
              };
              return newImages;
            });
          });
      });
    } else {
      setPhotoImages([]);
    }
  }, [varPhotoList,varMode]);

  //삭제를 선택한 경우
  const onSelectDeleteHandler = (e,jj_idx) => {
    //e.preventDefault();
    const bmode = e.currentTarget.checked;
    console.log(bmode);
    if(bmode) {
      if(!varDeleteList.includes(jj_idx)) {
        setDeleteList(varDeleteList.concat(jj_idx));
      }
    } else {
      if(varDeleteList.includes(jj_idx)) {
        const copyList = varDeleteList.filter(element=>element!==jj_idx);
        setDeleteList(copyList);
      }
    }
  }
  
  const onGoingDetailHandler = (e) => {
    localStorage.setItem('scrollIndex',varIndex);
    localStorage.setItem('scrollTop',window.scrollY);
    if(varMode===1) navigate(`/Doongji/DoongjiView/${varProductData.rp_idx}`);
    else navigate(`/Jangter/JangterView/${varProductData.jt_idx}`);
  }

  return isObjEmpty(varProductData)?null:(
    <>
      <div className="edit_on_wrap">
        <div className="terms_chk">
          <input type="checkbox" name="edit" className="chk1" id={"del_"+varIndex} 
                   checked={varDeleteList.length > 0 && varDeleteList.includes(varProduct.jj_idx)} onChange={e=>onSelectDeleteHandler(e,varProductData.jj_idx)}/>
          <i></i><label htmlFor={"del_"+varIndex}>&nbsp;</label>
        </div>
      </div>
      {/* 매물사진 슬라이드 */}
      <div className="swiper-container listbox listbox01">
        {varPhotoImages?.length <= 1?
          <div className="c_wrap" key={'photo_00'}>
            <div className="img_wrap2" style={{textAlign:'center',margin:'auto'}} onClick={e=>onGoingDetailHandler(e)}>
              {varPhotoImages?.length===0?
                <img src={noSlideImage} alt="" />
              :
              <div>
                {varPhotoImages[0]?.loading ? (
                  // Display loading indicator
                <div className="loading-indicator">
                  {/* Loading spinner */}
                  <div className="loader"></div>
                </div>
              ) : (
                <>
                  <img src={varMode===1?`${CONFIG.SERVER_HOST}/`+GetPhotoImage(varPhotoImages[0].file_name,'doongji'):`${CONFIG.SERVER_HOST}/`+GetPhotoImage(varPhotoImages[0].file_name,'jangter')} alt="" style={{objectFit: 'cover'}}/>
                  {/* <img src={`${CONFIG.SERVER_HOST}/water_m.png`} alt="Watermark" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' , width: '85px', height: 'auto', objectFit: 'cover', pointerEvents: 'none' }} /> */}
                </>
              )}
            </div>
              }
            </div>
          </div>
        :
          <Swiper slidesPerView={1.5} spaceBetween={5} loop={true} pagination={{clickable:true}} modules={[Pagination]} className="mySwiper">
            {varPhotoImages.map((photoObj,i)=>(
              <SwiperSlide className="swiper-slide" key={'photo_'+i}>
                <div className="c_wrap">
                  <div className="img_wrap" onClick={e=>onGoingDetailHandler(e)}>
                  {photoObj.loading ? (
                      // Display loading indicator
                      <div className="loading-indicator">
                        {/* Loading spinner */}
                        <div className="loader"></div>
                      </div>
                    ) : (
                      <>
                    <img src={varMode===1?`${CONFIG.SERVER_HOST}/`+GetPhotoImage(photoObj.file_name,'doongji'):`${CONFIG.SERVER_HOST}/`+GetPhotoImage(photoObj.file_name,'jangter')} alt="" style={{objectFit: 'cover'}}/>
                    {/* <img src={`${CONFIG.SERVER_HOST}/water_m.png`} alt="Watermark" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' , width: '55px', height: 'auto', objectFit: 'cover', pointerEvents: 'none' }} /> */}
                    </>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        }
      </div>
      {/* 매물정보 */}
      <div className="c_wrap" style={{borderRadius:"0px"}}>
      {varMode === 1?
        <div className="info01" style={{marginTop:"10px"}} onClick={e=>onGoingDetailHandler(e,varProductData.rp_idx)}>
          <div style={{marginBottom:"10px"}}>
            <div className="c_desc flex-row" style={{marginBottom:"10px"}}>
              {varProductData.rp_rush_sale===0?null:<span className="urgent" style={{marginRight:"10px"}}>급매</span>}
              <div>
                <h3 style={{fontWeight:"700"}}>{varProductData.rp_title}</h3>
              </div>
            </div> 
            <div className="c_dong"><span>{varProductData.rp_dongname}</span></div>&nbsp;
            {varProductData.rp_serious===0?null:<div className="c_type"><span>{varProductData.serious}</span></div>}
            <div className="c_price">
              <span className="c_rent_type">{varProductData.types}</span>&nbsp;
              <span className="price">{ShowDoongjiTitle2(varProductData,'/')}</span>
            </div>
          </div>
          <div className="c_hash">
            <ul>
              {varProductData.rp_serious<=5 ?
                <li className="c_option"><span>방{varProductData.rp_rooms}</span></li>
              :varProductData.rp_serious===6||varProductData.rp_serious===7 ?
                <li className="c_option"><span>룸{varProductData.rp_rooms}</span></li>
              :null}
              <li className="c_option"><span>{varProductData.rp_serious===9?addThousandPoint(varProductData.rp_sale_area):addThousandPoint(varProductData.rp_private_area)}평</span></li>
              {varProductData.rp_serious===9 ? null
              :varProductData.rp_exist_floor_type===0?
                <li className="c_option"><span>{varProductData.rp_exist_floor}층</span></li>
              : <li className="c_option">
                  <span>
                    {varProductData.rp_exist_floor_type===1?"건물전체"
                    :varProductData.rp_exist_floor_type===2?"지하"
                    :varProductData.rp_exist_floor_type===3?"반지하"
                    :varProductData.rp_exist_floor_type===4?"옥탑"
                    :null
                    }
                  </span>
                </li>
              }
              {varProductData.rp_serious===9 ? null
              :varProductData.rp_serious<=5 ?
                varProductData.rp_structure===0?null:<li className="c_option"><span>{varProductData.structure}</span></li>
              :varProductData.rp_terrace===0 ? null:
                <li className="c_option"><span>테라스</span></li>
              }
              {varProductData.rp_serious>5 || varProductData.rp_can_pets===0 ? null
              :varProductData.rp_can_pets===1 ? <li className="c_option"><span>반려동물</span></li>
              :<li className="c_option"><span>반려동물협의</span></li>
              }
              {varProductData.rp_serious===9 || varProductData.rp_elevator===0 ? null:
                <li className="c_option"><span>엘리베이터</span></li>
              }
            </ul>
          </div>
        </div>
      :
        <Link to={`/Jangter/JangterView/${varProductData.jt_idx}`}>
          <div className="c_wrap02">
            <div className="tit03">
              <span className="btn_st_A">{varProductData.jt_dongname}</span>{varProductData.jt_title}
            </div>
            <div className="descript-lines2">{varProductData.jt_comment}</div>
          </div>
        </Link>
      }  
      </div>
    </>
  );
}
export default JjimProduct;