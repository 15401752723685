/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"; 
import axios from 'axios';
import CONFIG from '../../configs/Configs';

import closeIcon from '../../images/icon_close.png';
import bulletIcon from '../../images/bullet.png';
import deleteGrayIcon from '../../images/icon_del_btn_gray.png';

const SearchKeyword = ({varPopupSearchKeyword,onPopupSearchKeywordHandler,setSearchingString}) => {
  const mem_idx = localStorage.getItem("mem_idx");
  const [varTmpSearchString,setTmpSearchString] = useState("");
  const [varKeywordList,setKeywordList] = useState([]);
  const [varResultSearchString,setResultSearchString] = useState('');

  useEffect(() => {
    GetKeywordList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varPopupSearchKeyword]);

  //입력되는 키워드 저장 변수
  const onChangeInputHandler = (e) => {
    const { value } = e.currentTarget;
    setTmpSearchString(value);
  }
  const onKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onUsingSearchKeywordHandler(e);
    }
  }
  //키워드 호출하기
  const GetKeywordList = async (e) => {
    const sendData = {mem_idx:mem_idx};
    //console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_get_keyword_list`,sendData).then((res) => {
      const datas = res.data;
      //console.log(datas);
      if(datas.retvalue === 1) {
        setKeywordList(datas.results);
      } else {
        setResultSearchString(datas.message);
        setTmpSearchString('');
        setKeywordList([]);
      }
    });
  }
  //키워드 삭제
  const DeleteKeywordList = async(keyword) => {
    const sendData = {keyword:keyword,mem_idx:mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_delete_keyword_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 0) {
        setResultSearchString(datas.message);
      }
    });
  }

  //키워드 검색실행 
  const UpdateKeywordHandler = async () => {
    const sendData = {keyword:varTmpSearchString,mem_idx:mem_idx};
    console.log(sendData);
    await axios.post(`${CONFIG.SERVER_HOST}/_update_keyword_list`,sendData).then((res) => {
      const datas = res.data;
      console.log(datas);
      if(datas.retvalue === 0) {
        alert(datas.message);
      }
    });
  }

  //검색된 키워드 선택
  const onSelectKeywordHandler = (e,keyword) => {
    e.preventDefault();
    setTmpSearchString(keyword);
  }
  //검색된 키워드 삭제
  const onDeleteKeywordHandler = (e,keyword) => {
    e.preventDefault();
    setTmpSearchString('');
    //목록에서만 키워드 삭제 --- 데이타베이스에서는 삭제 안함.
    const exceptLists = varKeywordList.filter(element=>element.key_name!==keyword);
    setKeywordList(exceptLists);
    DeleteKeywordList(keyword);
  }
  //매물검색 실행
  const onUsingSearchKeywordHandler = (e) => {
/*     if(varTmpSearchString === null || varTmpSearchString === "") {
      alert('검색할 키워드가 입력되어 있지 않았습니다.\n검색할 키워드를 먼저 입력하세요.'); return false;
    } */
    const exitList = varKeywordList.filter(el=>el.key_name===varTmpSearchString);
    if(exitList?.length === 0 && varTmpSearchString !== "" && mem_idx !== null) {  //새로 입력된 키워드가 저장되어 있지 않는 키워드이면 
      UpdateKeywordHandler();
    }
    setSearchingString(varTmpSearchString);
    console.log(varTmpSearchString);
    onPopupSearchKeywordHandler(e,-1);
  }
  const onClearKeywordHandler = (e) => {
    setTmpSearchString("");
    setSearchingString("");
    onPopupSearchKeywordHandler(e,false);
  }

  return (
    <div className="layer-pop full_pop search_popup" style={varPopupSearchKeyword?{display:"block"}:{display:"none"}}>
      <div className="pop_head">
        <div className="layer_tit">키워드로 매물 찾기</div>
        <div className="close_layer top_close_btn"><img src={closeIcon} alt="" onClick={e=>onClearKeywordHandler(e)}/></div>
      </div>
      <div className="layer_inner">
        <div className="center_wrap">
          <div className="con">
            <div className="searchbox ">
              <form method="post" name="searchfrm2">
                <fieldset className="search_open_btn">
                  <input type="text" className="input" name="searchString" value={varTmpSearchString} placeholder="키워드를 검색해보세요" onKeyPress={e=>onKeyPressHandler(e)} onChange={e=>onChangeInputHandler(e)}/>
                  <input type="button" className="btn" value="검색" onClick={e=>onUsingSearchKeywordHandler(e)}/>
                </fieldset>
              </form>
            </div>
            <div className="pop_subtit"><span className="bullet_wrap"><img src={bulletIcon} alt=""/></span>최근 검색어</div>
            <div className="keyword_wrap">
              <ul>
                {varKeywordList?.length === 0?<li>{varResultSearchString}</li>
                :varKeywordList.map((keyword,i)=>(
                  <li key={'keyword_'+i}>
                    <div onClick={e=>onSelectKeywordHandler(e,keyword.key_name)}>{keyword.key_name}</div>
                    <div className="del_btn"><img src={deleteGrayIcon} alt="" onClick={e=>onDeleteKeywordHandler(e,keyword.key_name)}/></div>
                  </li>
                ))}
              </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="btn_bottom one_btn">
      <button className="btn_style_yes" onClick={e=>onUsingSearchKeywordHandler(e)}>키워드로 매물 검색</button>
      </div>
  </div>
  );
}
export default SearchKeyword